import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from '../icon'

import './index.css'

const Toggle = ({ status, className }) => {
  return (
    <span className={cn('toggle', className)}>
      <Icon name={status ? 'close' : 'bottom'} />
    </span>
  )
}

Toggle.propTypes = {
  status: PropTypes.bool.isRequired
}

export default Toggle
