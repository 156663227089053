import React from 'react'
import { graphql } from 'gatsby'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import DatoCMSModel from '../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import Hero from '@v/seo-check-list/hero'
import SeoChecklist from '@v/seo-check-list/seo-checklist'

const Page = ({ data }) => {
  const { page } = data
  const model = new DatoCMSModel(page)
  const title = page.title
  const checklists = page.checklists.map(checklist => ({
    ...checklist,
    questions: parseSEOChecklistQA(checklist.questionsAndAnswers)
  }))

  // console.log(data, checklists)

  return (
    <Layout locale={page.locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      <Hero
        titleSmall={page.heroTitleSmall}
        titleBig={page.heroTitleBig}
        description={page.heroDescription}
        image={page.heroImage}
        imageAsset={page.heroImageAsset}
      />

      {checklists.map((checklist, i) => (
        <SeoChecklist
          key={i}
          title={checklist.title}
          questions={checklist.questions}
        />
      ))}
    </Layout>
  )
}

function parseSEOChecklistQA(qa) {
  const lines = qa
    .split('\n')
    .filter(line => !isEmpty(line) && line.match(/\S+/g))
  const records = lines.map((line, key) => {
    const isAnswer = line.indexOf('-') === 0
    try {
      if (!isAnswer) {
        const parts = line.split(':')
        return {
          type: 'question',
          point: Number(parts[0]) || 0,
          question: parts[1].trim()
        }
      }
    } catch (error) {
      console.error(`Line: ${key + 1}`)
      console.error(error)
    }

    // answers may contain colons
    const indexOfColon = line.indexOf(':')
    const category = line
      .slice(1)
      .slice(0, indexOfColon - 1)
      .trim()
    const answer = line.slice(indexOfColon + 1).trim()

    return {
      type: 'answer',
      category,
      answer
    }
  })
  const questionsWithAnswers = records.reduce((acc, record) => {
    if (record.type === 'question') {
      return acc.concat({ ...record, answers: [] })
    }

    last(acc).answers.push(record)
    return acc
  }, [])

  return questionsWithAnswers
}

export default Page

export const query = graphql`
  query SeoChecklistQuery($locale: String) {
    page: datoCmsSeoChecklistPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale

      title
      heroTitleSmall
      heroTitleBig
      heroDescription
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }
      checklists {
        title
        questionsAndAnswers
      }
    }
  }
`
