import React from 'react'

import PageHeader from '@c/page-header'
import SeoCheckListItem from '@c/seo-check-list-item'

const SeoChecklist = ({ title, questions }) => (
  <div className="container mt-80 mt-lg-120">
    <div className="row">
      <div className="col-12">
        <PageHeader title={title} type="h3" />

        <div>
          {questions.map((question, i) => (
            <SeoCheckListItem key={i} point={question.point}>
              <SeoCheckListItem.Header>
                {question.question}
              </SeoCheckListItem.Header>
              <SeoCheckListItem.Body>
                {question.answers.map((answer, i) => (
                  <SeoCheckListItem.Item key={i} type={answer.category}>
                    {answer.answer}
                  </SeoCheckListItem.Item>
                ))}
              </SeoCheckListItem.Body>
            </SeoCheckListItem>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default SeoChecklist
