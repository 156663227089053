import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import PropTypes from 'prop-types'

import FormCheckbox from '../form-checkbox'
import Toggle from '../toggle'
import Tag from '../tag'

import './index.css'

import chart1 from '@assets/seo-check-list/chart-1.svg'
import chart2 from '@assets/seo-check-list/chart-2.svg'
import chart3 from '@assets/seo-check-list/chart-3.svg'
import chart4 from '@assets/seo-check-list/chart-4.svg'
import chart5 from '@assets/seo-check-list/chart-5.svg'

function SeoCheckListItem({ children, point }) {
  const [isShow, setShow] = useState(false)

  const onToggle = () => {
    setShow(!isShow)
  }

  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props, point, isShow, onToggle })
  )

  return <div className="seo-check-list-item py-24">{childs}</div>
}

SeoCheckListItem.defaultProps = {
  point: 5
}

SeoCheckListItem.propTypes = {
  point: PropTypes.number,
  children: PropTypes.node
}

SeoCheckListItem.Header = function SeoCheckListItemHeader({
  point,
  isShow,
  onToggle,
  children
}) {
  return (
    <header className="d-flex align-items-center">
      {/* checkbox*/}
      <div className="flex-shrink-0">
        <FormCheckbox required={false} />
      </div>

      {/* title */}
      <h5 className="ml-24 ff-body fw-text-normal fs-large flex-grow-1">
        <button onClick={onToggle} type="button" className="ta-left">
          {children}
        </button>
      </h5>

      {/* point */}
      <div className="ml-24 point d-flex align-items-center flex-shrink-0">
        {point === 1 && <SVG src={chart1} />}
        {point === 2 && <SVG src={chart2} />}
        {point === 3 && <SVG src={chart3} />}
        {point === 4 && <SVG src={chart4} />}
        {point === 5 && <SVG src={chart5} />}
        <span className="h3 ml-16 c-red-dark">{point}</span>
      </div>

      {/* arrow */}
      <div className="ml-24 d-none d-lg-flex" onClick={onToggle}>
        <Toggle status={isShow} />
      </div>
    </header>
  )
}

SeoCheckListItem.Body = function SeoCheckListItemBody({ isShow, children }) {
  return isShow && <div className="mb-n16 mt-24 pb-8">{children}</div>
}

SeoCheckListItem.Item = function SeoCheckListItemItem({ type, children }) {
  return (
    <div className="seo-check-list-item-child d-flex align-items-center mb-16">
      {/* tag */}
      <Tag>{type}</Tag>
      {/* content */}
      <div className="ml-24">{children}</div>
    </div>
  )
}

export default SeoCheckListItem
